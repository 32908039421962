<template>
	<div class="CorpList">
		<div class="CorpList1">
			<div class="CorpList1_1">
				<el-input type="text" prefix-icon="el-icon-search" placeholder="请输入公司名称搜索" v-model="Pages.T_name"
					clearable @keyup.enter.native="searchValue"></el-input>
				<el-button type="primary" icon="el-icon-search" style="margin: 0 10px;" @click="searchValue()">搜索
				</el-button>
			</div>
			<div class="CorpList1_2" @click="ifyList=true">
				<el-button type="primary" icon="el-icon-plus">添加公司</el-button>
			</div>
		</div>
		<div class="CorpList2">
			<el-table :data="tableData" style="width: 100%" border>
				<!-- <el-table-column type="index"></el-table-column> -->
				<el-table-column label="公司名称">
					<template slot-scope="scope">
						<i class="el-icon-odometer" style="font-size: 20px;"></i>
						<span style="margin-left: 10px">{{ scope.row.T_name }}</span>
					</template>
				</el-table-column>
				<el-table-column label="用户名">
					<template slot-scope="scope">
						<span style="margin-left: 10px">{{ scope.row.T_user }}</span>
					</template>
				</el-table-column>
				<el-table-column label="密码">
					<template slot-scope="scope">
						<span style="margin-left: 10px">{{ scope.row.T_passstr }}</span>
					</template>
				</el-table-column>
				<el-table-column label="公开/隐藏" width="200"> size="small"
					<template slot-scope="scope">
						<el-switch v-model="scope.row.T_State" @change="switchs(scope.row)">
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<router-link to="/corpManagement">
							<el-button type="primary" icon="el-icon-edit-outline" size="mini"
								@click="handleEdit(scope.row)">任务管理</el-button>
						</router-link>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!--编辑、新增-->
		<el-dialog title="添加公司" :visible.sync="ifyList" width="30%" top="0">
			<el-form :model="form" :rules="rules" ref="ruleForm" label-width="80px">
				<el-form-item label="公司名称" prop="T_name">
					<el-input v-model="form.T_name" autocomplete="off" clearable></el-input>
				</el-form-item>
				<el-form-item label="用 户 名" prop="T_user">
					<el-input v-model="form.T_user" autocomplete="off" clearable></el-input>
				</el-form-item>
				<el-form-item label="密 码" prop="T_passstr">
					<el-input v-model="form.T_passstr" type="password" autocomplete="off" show-password></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="ifyList = false">取 消</el-button>
				<el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
			</div>
		</el-dialog>
		<div class="CorpList3">
			<el-pagination background @current-change="handleCurrentChange" :page-size="Pages.page_z"
				layout="total, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		UserList,
		UserAdd,//添加公司
		UserUp//显示隐藏
	} from '../../../api/CorpManage.js'
	export default {
		data() {
			return {
				ifyList: false,
				search: '',
				form: {
					T_name: '',
					T_user: '',
					T_pass:'',
					T_passstr: ''
				},
				rules: {
					T_name: [{
						required: true,
						message: '请输入公司名称',
						trigger: 'blur'
					}],
					T_user: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}],
					T_passstr: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}]
				},
				Pages: {
					page: 1,
					page_z: 10,
					T_name: null
				},
				total: 30, //总条数
				tableData: []
			}
		},
		mounted() {
			this.PostUserList()
		},
		methods: {
			PostUserList() {
				var _this = this
				UserList(this.Pages).then(res => {
					console.log('sss', res)
					_this.tableData = []
					if (res.data.Code == 200) {
						var Arr = res.data.Data.List
						_this.total = res.data.Data.Num
						if (Arr != null) {
							Arr.forEach(function(obj) {
								var objs = obj
								if (objs.T_State == 1) {
									objs.T_State = true
								} else {
									objs.T_State = false
								}
								_this.tableData.push(objs)
							})
						} else {
							console.log('长度为0')
						}
					}
					console.log(_this.tableData)
				})
			},
			submitForm(formName) { //提交编辑或者新增按钮
				var _this = this 
				_this.$refs[formName].validate((valid) => {
					if (valid) {
						_this.form.T_pass = _this.$md5(_this.form.T_passstr)
						UserAdd(_this.form).then(res=>{
							if(res.data.Code===200){
								_this.$message({
									message: '公司添加成功',
									type: 'success'
								});
								_this.ifyList = false
								_this.PostUserList()
							}
						})
					} else {
						console.log('验证未通过');
						return false;
					}
				});
			},
			searchValue() { //搜索
				this.Pages.page = 1
				this.PostUserList()
			},
			handleEdit(e) {
				this.$store.commit('CorpList',e)
			},
			switchs(e){
				console.log('ssss',e)
				if(e.T_State===true){
					e.T_State=1
				}else{
					e.T_State=0
				}
				UserUp(e).then(res=>{
					console.log(res)
					if(res.data.Code==200){
						this.PostUserList()
						if(e.T_State){
							this.$message({
								message: e.T_name+'（显示）设置成功',
								type: 'success'
							});
						}else{
							this.$message({
								message: e.T_name+'（隐藏）设置成功',
								type: 'success'
							});
						}
					}
				})
			},
			handleSizeChange(val) { //每页多少条
				console.log('当前页',val)
				this.Pages.page_z = val
				this.PostUserList()
			},
			handleCurrentChange(val) { //当前页
				console.log('当前页',val)
				this.Pages.page = val
				this.PostUserList()
			},
		}
	}
</script>

<style>
	@import url("../../../assets/css/el-dialog.css");

	.CorpList {
		background: #fff;
	}

	.CorpList1 {
		display: flex;
		align-items: center;
		padding: 10px;
	}

	.CorpList1_1 {
		display: flex;
		align-items: center;
		min-width: 500px;
	}

	.CorpList2 {
		margin-top: 15px;
		padding: 10px;
	}

	.CorpList3 {
		padding: 15px 10px;
	}
</style>
